<script setup lang="ts">
import {
  ConnectionLineType,
  MarkerType,
  VueFlow,
  useVueFlow,
} from '@vue-flow/core';
import AddStartNode from '~/components/canvas/AddStartNode.vue';
import AddStepEdge from '~/components/canvas/AddStepEdge.vue';
import AddStepNode from '~/components/canvas/AddStepNode.vue';
import ConditionEdge from '~/components/canvas/ConditionEdge.vue';
import ConditionNode from '~/components/canvas/ConditionNode.vue';
import DisplayNode from '~/components/canvas/DisplayNode.vue';
import LoopNode from '~/components/canvas/LoopNode.vue';
import NoteNode from '~/components/canvas/NoteNode.vue';
import ReviewNode from '~/components/canvas/ReviewNode.vue';
import StartNode from '~/components/canvas/StartNode.vue';
import StepEdge from '~/components/canvas/StepEdge.vue';
import StepNode from '~/components/canvas/StepNode.vue';
import TriggerNode from '~/components/canvas/TriggerNode.vue';
import WaitNode from '~/components/canvas/WaitNode.vue';
import ControlPanel from '~/components/editor/ControlPanel.vue';
import DropzoneBackground from '~/components/editor/DropzoneBackground.vue';
import Tutorial from '~/components/editor/Tutorial.vue';
import ConfigPanel from '~/components/editor/config/ConfigPanel.vue';
import Sidebar from '~/components/editor/sidebar/Sidebar.vue';
import ConfirmModal from '~/components/modals/ConfirmModal.vue';

const canvasStore = useCanvasStore();
const userStore = useUserStore();
const spellStore = useSpellsStore();
const tutorialStore = useTutorialStore();
const modal = useModal();

const { fitView, onNodesInitialized, getNodes, onInit } = useVueFlow({
  id: 'editor',
});
const { onDragOver, onDrop, onDragLeave, isDragOver } = useDragAndDrop();
const { selectedStep } = useSelectedStep();
const { elements } = storeToRefs(canvasStore);
const { addEdge } = canvasStore;
const { profile } = storeToRefs(userStore);

let unwatch: () => void;

// Load canvas and begin auto-save
onInit(() => {
  canvasStore.loadCanvas();

  setTimeout(() => {
    fitView({ duration: 200 });
  }, 300);

  unwatch = watchDebounced(
    elements,
    async () => {
      await canvasStore.saveCanvas();
    },
    { debounce: 2500, maxWait: 5000, deep: true },
  );

  if (
    !profile.value?.milestones.includes('tutorial') &&
    elements.value.find((e) => e.type === 'add-start')
  ) {
    tutorialStore.state = 'active';
  }
});

onNodesInitialized(() => {
  setTimeout(() => {
    fitView({ duration: 200 });
    getNodes.value?.forEach((node) => {
      if (!['add-step', 'add-start', 'note'].includes(node.type)) {
        canvasStore.validateStep(
          node.data.slug,
          node.data.key,
          node.data.options,
        );
      }
    });
  }, 300);
});

onBeforeRouteLeave((to, from, next) => {
  if (spellStore.liveGraph?.version === 'v0') {
    modal.open(ConfirmModal, {
      type: 'spell editor',
      action: 'Exit',
      message: 'Your spell will appear empty until you publish this draft.',
      isDangerous: true,
      onConfirm() {
        unwatch();
        next();
      },
      onClose() {
        next(false);
      },
    });
  } else {
    unwatch();
    next();
  }
});

const connectionLineStyle = {
  stroke: '#5344E5',
};
</script>
<template>
  <div class="w-full h-full relative" @drop="onDrop">
    <VueFlow
      id="editor"
      v-model="elements"
      :min-zoom="0.3"
      :max-zoom="1"
      :snap-to-grid="true"
      :snap-grid="[20, 20]"
      :select-nodes-on-drag="false"
      :connection-line-style="connectionLineStyle"
      :connection-line-type="ConnectionLineType.SmoothStep"
      :connection-line-options="{ markerEnd: MarkerType.Arrow }"
      :connect-on-click="false"
      :delete-key-code="false"
      :edge-updater-radius="30"
      :connection-radius="30"
      :auto-connect="addEdge"
      pan-on-scroll
      @dragover="onDragOver"
      @dragleave="onDragLeave"
    >
      <DropzoneBackground
        :style="{
          backgroundColor: isDragOver ? '#e7f3ff' : 'transparent',
          transition: 'background-color 0.2s ease',
        }"
      />
      <template #node-step="props">
        <StepNode v-bind="props" />
      </template>
      <template #node-start="props">
        <StartNode v-bind="props" />
      </template>
      <template #node-display="props">
        <DisplayNode v-bind="props" />
      </template>
      <template #node-trigger="props">
        <TriggerNode v-bind="props" />
      </template>
      <template #node-add-step="props">
        <AddStepNode v-bind="props" />
      </template>
      <template #node-add-start="props">
        <AddStartNode v-bind="props" />
      </template>
      <template #node-condition="props">
        <ConditionNode v-bind="props" />
      </template>
      <template #node-review="props">
        <ReviewNode v-bind="props" />
      </template>
      <template #edge-step="props">
        <StepEdge v-bind="props" />
      </template>
      <template #edge-add-step="props">
        <AddStepEdge v-bind="props" />
      </template>
      <template #edge-condition="props">
        <ConditionEdge v-bind="props" />
      </template>
      <template #node-note="props">
        <NoteNode v-bind="props" />
      </template>
      <template #node-wait="props">
        <WaitNode v-bind="props" />
      </template>
      <template #node-loop="props">
        <LoopNode v-bind="props" />
      </template>
    </VueFlow>

    <Sidebar class="absolute inset-y-0 left-5" />

    <ControlPanel class="absolute left-5 bottom-7" />

    <transition name="fade" class="absolute inset-y-0 right-5">
      <ConfigPanel v-if="selectedStep" :key="selectedStep.id" />
    </transition>
    <Tutorial v-if="tutorialStore.state === 'active'" />
  </div>
</template>
<style lang="scss">
.vue-flow__panel {
  margin: spacing(l);
}

.vue-flow__edges,
.vue-flow__nodes {
  transition: opacity easing(s);
}

.is-loading {
  .vue-flow__edges,
  .vue-flow__nodes {
    opacity: 0;
  }
}

[contenteditable] {
  outline: 0px solid transparent;
}
</style>
