<script setup lang="ts">
import definitions from '@respell/steps';
import { useVueFlow } from '@vue-flow/core';
import AppStepLabel from '~/components/app/AppStepLabel.vue';
import ConditionSettings from '~/components/editor/config/ConditionSettings.vue';
import LoopSettings from '~/components/editor/config/LoopSettings.vue';
import OutputsPane from '~/components/editor/config/OutputsPane.vue';
import ReviewSettings from '~/components/editor/config/ReviewSettings.vue';
import StartSettings from '~/components/editor/config/StartSettings.vue';
import StepSettings from '~/components/editor/config/StepSettings.vue';
import StepWarning from '~/components/editor/config/StepWarning.vue';
import TriggerSettings from '~/components/editor/config/TriggerSettings.vue';
import VariableDisplay from '~/components/editor/config/VariableDisplay.vue';
import VariableForm from '~/components/editor/config/VariableForm.vue';
import WaitSettings from '~/components/editor/config/WaitSettings.vue';
import { addStartType, triggerType } from '~/stores/canvas';

const canvasStore = useCanvasStore();
const { removeSelectedNodes } = useVueFlow({ id: 'editor' });
const isEditingLabel = ref(false);

const { variableState, variable, errors, trigger, configTab } =
  storeToRefs(canvasStore);
const { selectedStep } = useSelectedStep();

const stepType = computed(() => {
  return selectedStep.value?.type === 'trigger'
    ? triggerType
    : selectedStep.value?.type === 'add-start'
      ? addStartType
      : definitions[selectedStep.value?.data.key];
});

const close = () => {
  removeSelectedNodes([selectedStep.value]);
};

const clearVariable = () => {
  variableState.value = null;
  variable.value = null;
  configTab.value = 0;
};

defineShortcuts({
  escape: {
    handler: () => {
      if (variableState.value) {
        clearVariable();
      } else {
        close();
      }
    },
  },
});

const triggerValidated = computed(() => !!trigger.value?.integration);

const selectedStepValidated = computed(() => {
  const selectedStepErrors = errors.value[selectedStep.value?.id];

  return selectedStepErrors === undefined || !selectedStepErrors.length;
});

const validated = computed(() =>
  stepType.value.key === 'trigger'
    ? triggerValidated.value
    : selectedStepValidated.value,
);

watchImmediate(selectedStep, () => {
  clearVariable();
});

const isStart = computed(() =>
  ['trigger', 'add-start', 'start'].includes(selectedStep.value?.type),
);

const menuOptions = computed(() => {
  const baseOptions = [];
  if (!!stepType.value?.documentationLink) {
    baseOptions.push({
      name: 'Docs',
      icon: 'i-ph-book',
      color: 'black',
      action: async () => {
        await navigateTo(stepType.value?.documentationLink, { external: true });
      },
    });
  }
  if (!isStart.value) {
    baseOptions.push(
      {
        name: 'Rename',
        icon: 'i-ph-text-aa',
        color: 'black',
        action: () => (isEditingLabel.value = true),
      },
      {
        name: 'Delete',
        icon: 'i-ph-trash-simple',
        color: 'rose',
        action: () =>
          canvasStore.removeStep(selectedStep.value?.data.slug ?? ''),
      },
    );
  }
  return baseOptions;
});

const configOptions = [
  {
    slot: 'options',
    label: 'Options',
    icon: 'i-ph-sliders-horizontal',
    activeIcon: 'i-ph-sliders-horizontal-fill',
  },
  {
    slot: 'outputs',
    label: 'Outputs',
    icon: 'i-ph-diamonds-four',
    activeIcon: 'i-ph-diamonds-four-fill',
  },
];
</script>
<template>
  <div class="max-w-lg w-[40vw] min-w-[450px] grow py-l">
    <div
      :id="`${selectedStep?.type}-settings`"
      class="rounded-2xl divide-y flex flex-col justify-start overflow-hidden bg-container h-full shadow-select border-container"
    >
      <div
        class="flex flex-row px-l py-m justify-start w-full gap-2.5"
        :class="{ 'bg-primary-100': isStart }"
      >
        <UIcon
          :name="stepType?.icon"
          class="rounded-full border-gray-50 text-3xl outline outline-gray-200 border shrink-0"
        />

        <UInput
          v-if="isEditingLabel"
          v-model="selectedStep.label"
          variant="none"
          placeholder="Name this step"
          :padded="false"
          autofocus
          size="lg"
          class="grow"
          :ui="{
            variant: { none: 'title text-lg' },
          }"
          @blur="isEditingLabel = false"
        />

        <UTooltip
          v-else
          :prevent="isStart"
          class="w-fit truncate"
          text="Click to edit this step's label"
        >
          <AppStepLabel
            :label="selectedStep?.label"
            :step-name="stepType?.name"
            size="lg"
            @click="isStart ? null : (isEditingLabel = true)"
          />
        </UTooltip>

        <UButton
          v-if="variableState"
          icon="i-ph-caret-left"
          size="md"
          color="gray"
          variant="ghost"
          label="Back"
          :trailing="false"
          class="ml-auto"
          @click="clearVariable"
        />
        <div v-else class="ml-auto flex gap-3">
          <div v-if="selectedStep?.type === 'step'" class="contents">
            <UIcon
              v-if="validated"
              name="i-ph-check-circle-fill"
              class="text-green-500 text-xl"
            />
            <UIcon
              v-else
              name="i-ph-x-circle-fill"
              class="text-red-500 text-xl"
            />
          </div>
          <USelectMenu v-if="menuOptions.length" :options="menuOptions">
            <UButton
              icon="i-ph-dots-three-outline-vertical-fill"
              size="lg"
              :padded="false"
              color="gray"
              variant="link"
            />
            <template #option="{ option }">
              <UButton
                :icon="option.icon"
                size="md"
                :color="option.color"
                variant="ghost"
                :label="option.name"
                :trailing="false"
                @click="option.action"
              />
            </template>
          </USelectMenu>
          <UButton
            :padded="false"
            color="gray"
            variant="link"
            icon="i-ph-x"
            @click="close"
          />
        </div>
      </div>

      <p v-if="!isStart" class="body w-full dimmed py-m px-l">
        {{ stepType?.description }}
      </p>

      <VariableForm v-if="variableState" />
      <UTabs
        v-else-if="selectedStep?.type === 'step'"
        v-model="configTab"
        :items="configOptions"
        class="w-full h-full"
        :ui="{
          container: 'overflow-y-scroll !mt-0 !pt-4 h-full max-h-full',
          wrapper: 'divide-y',
          list: {
            padding: 'px-1 py-1',
            base: 'my-1.5 mx-7',
            width: 'w-fit',
            height: 'h-fit',
          },
        }"
      >
        <template #icon="{ item, selected }">
          <UIcon
            :name="selected ? item.activeIcon : item.icon"
            class="w-4 h-4 flex-shrink-0"
          />
        </template>

        <template #default="{ item, selected }">
          <div class="flex items-center gap-2 relative truncate px-2">
            <span class="truncate">{{ item.label }}</span>

            <span
              v-if="selected"
              class="absolute -right-4 w-2 h-2 rounded-full bg-primary-500 dark:bg-primary-400"
            />
          </div>
        </template>
        <template #options="{ item }">
          <StepSettings />
        </template>
        <template #outputs="{ item }">
          <OutputsPane />
        </template>
      </UTabs>
      <div
        v-else
        class="flex flex-col px-7 grow pb-48 justify-start pt-3 w-full h-full max-h-full overflow-y-scroll"
      >
        <transition name="fade">
          <StepWarning
            v-if="canvasStore.errors[selectedStep.id]?.length"
            :errors="canvasStore.errors[selectedStep.id]"
            class="shrink-0"
          />
        </transition>
        <StartSettings v-if="isStart" />
        <TriggerSettings v-if="selectedStep?.type === 'trigger'" />
        <ConditionSettings v-else-if="selectedStep?.type === 'condition'" />
        <ReviewSettings v-else-if="selectedStep?.type === 'review'" />
        <WaitSettings v-else-if="selectedStep?.type === 'wait'" />
        <LoopSettings v-else-if="selectedStep?.type === 'loop'" />
        <VariableDisplay v-else />
      </div>
    </div>
  </div>
</template>
<style lang="scss">
// Target accordion item
[id^='headlessui-disclosure-button-'] + * {
  width: 100%;
}
</style>
